import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import {LANG_DEFAULT, LANG_LOCALSTORAGE, LANG_SUPPORT} from '../../configs/system.config'
import ru from './locales/ru/translation.json'
import en from './locales/en/translation.json'

// todo Добавить lazy load для файлов переводов
const translations: any = {
  ru, en
}

const supportedLngs = LANG_SUPPORT.map(lang => lang.split(':')[0])

const resources = supportedLngs.reduce((all: any, lang) => {
  return {...all, [lang]: {translation: translations[lang]}}
}, {})


//Retrieve the saved value from localstorage
export const defineStartLang = () => {
  return window.localStorage.getItem(LANG_LOCALSTORAGE) || LANG_DEFAULT
}

export default i18next
  .use(initReactI18next)
  .init({
    // debug: true,
    lng: defineStartLang(),
    fallbackLng: LANG_DEFAULT,
    supportedLngs,
    resources
  })
