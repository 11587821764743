import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {defineStartLang} from '../../modules/i18n/i18next'
import {LANG_LOCALSTORAGE} from '../../configs/system.config'
import i18next from 'i18next'

export interface SystemState {
  locale: string
  isOpenMenu: boolean
}

const initialState: SystemState = {
  locale: defineStartLang(),
  isOpenMenu: false
}

export const asyncSwitchLocale = createAsyncThunk(
  'system/switchLocale',
  async (locale: string) => {
    await i18next.changeLanguage(locale)
    return locale
  }
)

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    anyHandler: (state, action) => {
      state.locale = action.payload.locale
    },
    toggleMenu: (state, action) => {
      state.isOpenMenu = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncSwitchLocale.pending, (state) => {
        state.locale = 'loading'
      })
      .addCase(asyncSwitchLocale.fulfilled, (state, action) => {
        window.localStorage.setItem(LANG_LOCALSTORAGE, action.payload)
        state.locale = action.payload
      })
  }
})

export const {anyHandler, toggleMenu} = systemSlice.actions

export default systemSlice.reducer
