import React from 'react'

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh'
}

export const FullScreenLoader = () => {
  return (
    <div style={style}>
      <i className="fas fa-spinner fa-spin fa-3x"/>
    </div>
  )
}
