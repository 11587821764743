import React, {createContext, useState} from 'react'
import iframeCall from './iframe-call'
import {Button, Modal} from 'antd'

interface IframeContextType {
  open: boolean
  setCall: () => void
}

const IframeContext = createContext<IframeContextType>({
  open: false,
  setCall: () => {
  }
})

export function useIframe(): IframeContextType {
  return React.useContext(IframeContext)
}

function IframeProvider({children}: { children: React.ReactNode }) {
  const [open, setOpen] = useState<boolean>(false)

  const setCall = () => {
    if (!open) {
      setOpen(true)
      iframeCall(setOpen)
    }
  }

  return (
    <IframeContext.Provider value={{open, setCall}}>
      <Modal
        centered
        open={open}
        className="as__modal-iframe"
        forceRender={true}
        maskClosable={false}
        closeIcon={null}
        closable={false}
        width={700}
        footer={null}
      >
        <div id="asIframeContainer" className="as__iframe-container"/>
        <div className="as__modal_btn-container">
          <Button type="primary" danger size="large" id="asBtnCallClose" className="as__btn-call-close">
            <i className="fa-solid fa-phone-slash"/> Завершить звонок
          </Button>
        </div>
      </Modal>
      {children}
    </IframeContext.Provider>
  )
}

export default IframeProvider
