type Shades = {
  light: string
  dark: string
}

const COLOR: {
  primary: Shades
  secondary: Shades
  other: Shades
} = {
  primary: {
    light: '#11036A',
    dark: '#11036A'
  },
  secondary: {
    light: '#F34C17',
    dark: '#EC400A'
  },
  other: {
    light: '#FFCD36',
    dark: '#FFCD36'
  }
}

export {
  COLOR
}
