import React, {lazy, Suspense} from 'react'
import {ConfigProvider} from 'antd'
import IframeProvider from './modules/iframe-call/IframeProvider'
import AntdCustomTheme from './configs/antd-theme.config'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {FullScreenLoader} from './components/ui/loaders/FullScreenLoader'
import {PATHS} from './configs/system.config'
import './App.scss'

const Main = lazy(() => import('pages/main/Main'))

function App() {
  return (
    <ConfigProvider theme={AntdCustomTheme}>
      <IframeProvider>
        <div className="as__app">
          <BrowserRouter>
            <Suspense fallback={<FullScreenLoader/>}>
              <Routes>
                <Route path={PATHS.main} element={<Main/>}/>
                <Route path='*' element={<div>Not Found</div>}/>
              </Routes>
            </Suspense>
          </BrowserRouter>
        </div>
      </IframeProvider>
    </ConfigProvider>
  )
}

export default App
