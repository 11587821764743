import './iframe-call.scss'

const iframeCall = (setOpen: (state: boolean) => void) => {
  let asIframeContainer: HTMLElement | null = document.getElementById('asIframeContainer')
  let asBtnCallClose: HTMLElement | null = document.querySelector('#asBtnCallClose')

  if (asIframeContainer === null) return
  asIframeContainer.innerHTML = ''
  let cshIframe: HTMLIFrameElement = document.createElement('iframe')
  // let cshLinkVideoCall = `https://dashboard.callshark.ru/calls/callHttp?c=1220&g=0&r=-1&s=4130&mode=video&typeCall=i&cshUrlCall=(${window.location.href})&cshEntryPoint=(${document.referrer})` // local
  let cshLinkVideoCall = `https://dashboard.callshark.ru/calls/callHttp?c=1598&g=0&r=-1&s=5328&mode=video&typeCall=i&cshUrlCall=(${window.location.href})&cshEntryPoint=(${document.referrer})` // callshark

  cshIframe.setAttribute('src', cshLinkVideoCall)
  cshIframe.setAttribute('height', '100%')
  cshIframe.setAttribute('width', '100%')
  cshIframe.setAttribute('allow', 'geolocation; microphone; camera')
  cshIframe.setAttribute('allowfullscreen', 'true')

  asIframeContainer.appendChild(cshIframe)

  if (asBtnCallClose != null) {
    asBtnCallClose.classList.remove('as__btn-call-close_hide')
  }

  if (asBtnCallClose !== null) {
    asBtnCallClose.onclick = () => {
      if (cshIframe !== null) {
        if (asBtnCallClose !== null) {
          // asBtnCallClose.classList.add('as__btn-call-close_hide')
          setOpen(false)
          // @ts-ignore
          cshIframe.contentWindow.postMessage("close", 'https://dashboard.callshark.ru')
          setTimeout(() => {
            cshIframe.remove()
          }, 700)
        }
      }
    }
  }

  return cshIframe
}

export default iframeCall
