import {COLOR} from './dev.config'

const AntdCustomTheme = {
  token: {
    colorPrimary: COLOR.primary.light,
    borderRadius: 10
  }
}

export default AntdCustomTheme
