const LANG_DEFAULT: string = 'ru'
const LANG_SUPPORT: string[] = [ // ex: code:title
  'ru:Русский', 'en:English'
]

//localstorage keys
const LANG_LOCALSTORAGE: string = 'lang'

interface path {
  readonly [key: string]: string
}

export const PATHS: path = {
  main: '/',
  test: '/test',
}

export const DATA_SITE = {
  // phone: '+7 495 567 8978',
  // phone: '+7 495 984 6073',
  phone: '+7 916 517 0296',
  telegram_link: 'https://t.me/callsharkvideochat',
  vk_link: 'https://vk.com/callshark',
  registry_link: 'https://dashboard.callshark.ru/registration?lang=ru'
}

export {
  LANG_DEFAULT,
  LANG_SUPPORT,
  LANG_LOCALSTORAGE,
}
